import * as React from 'react'

import { Box, Container, Grid } from '@mui/material'
import { PageProps, graphql } from 'gatsby'

import {
  BlogPostCard,
  Image,
  Section,
  SectionWrapper,
  SeoHeaders,
  Subtitle,
  Title,
} from 'shared/components'

import SugarBlog from '../../images/blog/sugar-blog-logo.svg'
import Layout from '../../layouts/landing'

import type { BlogAuthor, BlogImageData } from 'shared/components'
import '@fontsource/covered-by-your-grace/400.css'

type BlogPostData = {
  id: string
  slug: string
  category: string
  createdAt: string
  title: string
  heroImage: BlogImageData
  authors: BlogAuthor[]
}

type QueryProps = {
  allContentfulBlogPost: {
    edges: {
      node: BlogPostData
    }[]
  }
}

export const query = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          slug
          category
          createdAt
          title
          heroImage {
            gatsbyImageData(formats: WEBP, width: 600, quality: 90)
          }
          authors {
            name
            avatar {
              gatsbyImageData(formats: WEBP, width: 40, quality: 90)
            }
            bio {
              raw
            }
          }
        }
      }
    }
  }
`

const SapiensText = () => (
  <span
    style={{
      fontFamily: '"Covered By Your Grace", serif',
      fontStyle: 'italic',
      fontSize: '1.25em',
      marginLeft: '0.1em',
      marginRight: '0.15em',
    }}
  >
    Homo&nbsp;sapiens
  </span>
)

const BlogIndex = ({ data: { allContentfulBlogPost } }: PageProps<QueryProps>) => (
  <Layout>
    <SeoHeaders
      title='Bitcoin, criptomonendas y blockchain para Homo sapiens de toda Latinoamérica'
      description={('Explora Bitcoin, criptomonedas y blockchain con Sugar-blog.'
      + 'Guías, análisis y noticias para latinoamericanos. Haz crecer tu inversión inteligentemente.'
      )}
      ogImage={SugarBlog}
    />
    <Container maxWidth='lg'>
      <SectionWrapper>
        <Section>
          <Image
            src={SugarBlog}
            alt='Sugar-Blog'
            height='120px'
            fit='contain'
            shift='top'
          />
          <Subtitle
            mainTitle
            color='text.secondary'
            fontWeight={300}
            align='center'
          >
            Bitcoin, criptomonendas y blockchain para <SapiensText /> de toda Latinoamérica
          </Subtitle>
        </Section>
        <Section>
          <Title
            textAlign='center'
            gutterBottom
          >
            Posts Recientes
          </Title>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              rowSpacing={9}
              columnSpacing={3}
              alignItems='stretch'
            >
              {allContentfulBlogPost.edges.map(({ node }, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  key={index}
                >
                  <BlogPostCard
                    urlPrefix='/blog/posts'
                    slug={node.slug}
                    image={node.heroImage}
                    title={node.title}
                    category={node.category}
                    createdAt={node.createdAt}
                    authors={node.authors}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Section>
      </SectionWrapper>
    </Container>
  </Layout>
)

export default BlogIndex
